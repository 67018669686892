import React, { useMemo } from 'react';
import { useStoreContext } from '../context/store';
import carita_excelente from '../assets/excelente.png';
import carita_bueno from '../assets/bueno.png';
import carita_regular from '../assets/regular.png';
import carita_mal from '../assets/mal.png';
import carita_na from '../assets/na-mini.png';
import smile_csat_excelente from '../assets/csat-excelente.png';
import smile_csat_bueno from '../assets/csat-bueno.png';
import smile_csat_regular from '../assets/csat-regular.png';
import smile_csat_mal from '../assets/csat-mal.png';
import smile_csat_malisimo from '../assets/csat-malisimo.png';
import { useI18n } from '../utils/i18n';

import get from 'lodash/get';
import { useTheme } from '../hooks/sdk';
import clsx from 'clsx';
import { replaceVariables } from '../utils/replaceVariables';
import { set } from 'lodash';

const QuestionsMatrixView = ({ questionsMatrix, values: answers }) => {
  const { context, instance } = useStoreContext();

  const { smilesCsat: themeSmilesCsat, smiles: themeSmiles } = useTheme();

  const t = useI18n();
  const { showErrors, contact, store, campaign } = context.data;
  const firstNPSQuestion = questionsMatrix.getSubModules()[0];
  const settings = get(firstNPSQuestion, 'metadata.settings', {});

  let inputIcons = [
    {
      value: 4,
      alt: 'excellent',
      score: 10,
      src: themeSmiles
        ? get(themeSmiles[10], 'src', carita_excelente)
        : carita_excelente,
    },
    {
      value: 3,
      alt: 'good',
      score: 7,
      src: themeSmiles
        ? get(themeSmiles[7], 'src', carita_bueno)
        : carita_bueno,
    },
    {
      value: 2,
      score: 4,
      alt: 'regular',
      src: themeSmiles
        ? get(themeSmiles[4], 'src', carita_regular)
        : carita_regular,
    },
    {
      value: 1,
      score: 1,
      alt: 'bad',
      src: themeSmiles ? get(themeSmiles[1], 'src', carita_mal) : carita_mal,
    },
  ];

  const csatSmiles4 = [
    {
      value: 4,
      score: 10,
      alt: 'excellent',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[5], 'src', smile_csat_excelente)
        : smile_csat_excelente,
    },
    {
      value: 3,
      score: 7,
      alt: 'good',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[4], 'src', smile_csat_bueno)
        : smile_csat_bueno,
    },
    {
      value: 2,
      score: 4,
      alt: 'regular',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[3], 'src', smile_csat_regular)
        : smile_csat_regular,
    },
    {
      value: 1,
      score: 1,
      alt: 'bad',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[2], 'src', smile_csat_mal)
        : smile_csat_mal,
    }
  ]

  let csatSmiles = [
    {
      value: 5,
      score: 10,
      alt: 'excellent',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[5], 'src', smile_csat_excelente)
        : smile_csat_excelente,
    },
    {
      value: 4,
      score: 7.75,
      alt: 'good',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[4], 'src', smile_csat_bueno)
        : smile_csat_bueno,
    },
    {
      value: 3,
      score: 5.5,
      alt: 'regular',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[3], 'src', smile_csat_regular)
        : smile_csat_regular,
    },
    {
      value: 2,
      score: 3.25,
      alt: 'bad',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[2], 'src', smile_csat_mal)
        : smile_csat_mal,
    },
    {
      value: 1,
      score: 1,
      alt: 'worst',
      src: themeSmilesCsat
        ? get(themeSmilesCsat[1], 'src', smile_csat_malisimo)
        : smile_csat_malisimo,
    },
  ];

  const { text: matrixTitle, style: titleStyle } =
    questionsMatrix.getMatrixTitle();

  const errors = questionsMatrix.getErrors({ answers });

  const motives = questionsMatrix.getMotives();

  const hasQuestionWithNA = questionsMatrix.hasQuestionWithNA();

  if (hasQuestionWithNA !== undefined) {
    inputIcons.push({
      value: 0,
      alt: 'NA',
      score:null,
      src: t(carita_na),
    });
    csatSmiles.push({
      value: 0,
      score: null,
      alt: 'NA',
      src: t(carita_na),
    });
    csatSmiles4.push({
      value: 0,
      score: null,
      alt: 'NA',
      src: t(carita_na),
    });
  }

  const { text: description, style: descriptionStyle } =
    questionsMatrix.getMatrixDescription();

  const voteFontSize = questionsMatrix.getMatrixVoteFontSize();

  const score_type = questionsMatrix.getMatrixScoreType();

  const matrixOrder = questionsMatrix.getMatrixOrder();
  const matrixScoreLabels = questionsMatrix.getMatrixScoreLabels();
  const topLayout = questionsMatrix.getMatrixTopLayout();

  if (matrixOrder === 'reverse') {
    inputIcons.reverse();
    csatSmiles.reverse();
  }

  /* NPS Headers */
  const NPSHeaders = useMemo(() => {
    const initialHeaders = [
      { value: 0, ui_name: '0' },
      { value: 1, ui_name: '1' },
      { value: 2, ui_name: '2' },
      { value: 3, ui_name: '3' },
      { value: 4, ui_name: '4' },
      { value: 5, ui_name: '5' },
      { value: 6, ui_name: '6' },
      { value: 7, ui_name: '7' },
      { value: 8, ui_name: '8' },
      { value: 9, ui_name: '9' },
      { value: 10, ui_name: '10' },
    ];

    if (score_type === 'numeric') {
      const firstNPSQuestion = questionsMatrix.getSubModules()[0];
      const min = get(firstNPSQuestion, 'metadata.settings.score.min', 0);
      const max = get(firstNPSQuestion, 'metadata.settings.score.max', 10);
      const withNA = get(
        firstNPSQuestion,
        'metadata.settings.allowScoreNA',
        false
      );

      const updatedHeaders = initialHeaders
        .map((header) => {
          if (header.value < min || header.value > max) return null;
          const score = ((header.value - min) * 9) / (max - min) + 1;
          return { ...header, score };
        })
        .filter((header) => header);
      if (withNA)
        updatedHeaders.push({
          value: 0,
          score: null,
          ui_name: 'N/A',
        });

      return updatedHeaders;
    }

    return initialHeaders;
  }, [score_type, questionsMatrix]);

  const columns = useMemo(() => {
    const columns = [
      {
        fixed: true,
        key: 'questions-name',
        render: ({ question, value }) => {
          const name =
            (get(question, 'name') || get(question, 'nombre')) ===
            'write-question'
              ? t(get(question, 'name') || get(question, 'nombre'))
              : get(question, 'name') || get(question, 'nombre');
          const { settings } = question.metadata;
          return (
            <>
              <div
                data-testid={`name${question.id}`}
                style={{ fontSize: get(settings, 'fontSize') }}
                className="matrixQuestion"
              >
                {replaceVariables(name, store, contact, campaign)}
              </div>
              {showErrors && value && get(value, 'error') === true && (
                <div className={'error'}>*</div>
              )}
            </>
          );
        },
      },
    ];

    let headers = [];
    const firstNPSQuestion = questionsMatrix.getSubModules()[0];
    let colorLabels = get(firstNPSQuestion,'metadata.settings.score.colorLabels',[]);
    if (matrixOrder === 'reverse') colorLabels = colorLabels.reverse();
    const min = get(firstNPSQuestion, 'metadata.settings.score.min', 0);
    const max = get(firstNPSQuestion, 'metadata.settings.score.max', 10);
    const naLabel = get(firstNPSQuestion,'metadata.settings.naLabel');


    if (score_type === 'smiles') headers = inputIcons;
    if (score_type === 'csat' && topLayout === 'smiles'){
      if(max === 5) {
        headers = csatSmiles;
      } else {
        headers = csatSmiles4;
      }
    } 
    if (score_type === 'csat' && !topLayout){
      headers = matrixScoreLabels.map((label,index)=>{
        return {
          value:index+1,
          label:label,
          score:(((index+1) - min) * 9) / (max - min) + 1
      }});
      if(hasQuestionWithNA !== undefined) headers.push({
        label:naLabel ? naLabel :'N/A',
        value:0,
        score: null
      })
      if(matrixOrder === 'reverse') headers.reverse()
    } 
    if (score_type === null || !score_type) headers = motives;
    if (score_type === 'numeric') headers = NPSHeaders;
    if(headers.length) {
      if(hasQuestionWithNA && naLabel){
        const indexNa = headers.findIndex(header=> header?.score === null);
        set(headers[indexNa],'src', undefined);
        set(headers[indexNa],'label',naLabel);
        set(headers[indexNa],'ui_name',naLabel);
      }
    }

    headers.forEach((inputIcon, index) => {
      columns.push({
        key: `${inputIcon.name}-${index}`,
        title:
          score_type && score_type !== 'numeric' ? (
            get(inputIcon, 'src') ? (
              <img
                src={get(inputIcon, 'src')}
                alt={t(get(inputIcon, 'alt'))}
                style={{ width: '33px', textAlign: 'center' }}
              />
            ) : (
              <span style={{ color: get(colorLabels, index, 'initial') }}>
                {t(inputIcon.label)}
              </span>
            )
          ) : (
            <div
              style={{
                textAlign: 'center',
                fontSize: voteFontSize ? voteFontSize : '12px',
              }}
              className="matrix-column-name"
            >
              {get(inputIcon, 'ui_name') || get(inputIcon, 'name')}
            </div>
          ),
        render: ({ question, value }) => (
          <div
            className="preguntaAgrupadaInputStyle"
            data-testid={
              (
                get(question, 'id') +
                '' +
                get(
                  inputIcon,
                  'score',
                  score_type === null || !score_type
                    ? `motive${get(motives, `${index}.id`)}`
                    : null
                )
              ).split('.')[0] //should be score
            }
            onClick={() => {
              const moduleAnswer = instance.getModuleAnswerByIndex(
                question.index
              );
              if (score_type) {
                if (moduleAnswer)
                  moduleAnswer.setScore(inputIcon?.score ?? null);
              } else {
                if (moduleAnswer) {
                  const motivoId = get(inputIcon, 'id');
                  moduleAnswer.setMotives([{ id: motivoId, comment: null }], {
                    preventDirty: false,
                  });
                }
              }
            }}
          >
            <div
              className={
                value && !get(value, 'dirty')
                  ? 'estadoInicial'
                  : value &&
                    ((score_type &&
                      get(inputIcon, 'score', null) === get(value, 'score')) ||
                      (!score_type &&
                        get(inputIcon, 'id', null) ===
                          get(value, 'motives.0.id')))
                  ? 'select'
                  : 'sinselect'
              }
            />
          </div>
        ),
      });
    });

    return columns;
  }, [inputIcons, csatSmiles, matrixScoreLabels, motives, score_type]);

  const dataSource = useMemo(() => {
    return questionsMatrix.modules.map((question) => ({
      question,
      dataIndex: question.index,
      key: question.index,
      value: get(answers, question.index),
    }));
  }, [questionsMatrix, answers, questionsMatrix?.modules]);

  const isLongTable = columns.length > 10;

  const lessPadding = get(settings, 'lessPadding', false);

  return (
    <div>
      <div
        className={clsx(
          'principalAgrupada',
          isLongTable && 'container-long-matrix'
        )}
      >
        {description &&
          get(settings, 'descriptionPosition', 'above') === 'above' && (
            <div style={descriptionStyle} className={'question-description'}>
              {description}
            </div>
          )}
        {matrixTitle && (
          <h3
            style={titleStyle}
            className={clsx('pregunta', isLongTable && 'title-long-matrix')}
          >
            {matrixTitle === 'write-title' ? t(matrixTitle) : matrixTitle}
          </h3>
        )}
        {description &&
          get(settings, 'descriptionPosition', 'above') === 'below' && (
            <div
              style={{
                ...descriptionStyle,
                marginBottom: '20px',
                marginTop: '0px',
              }}
              className={'question-description'}
            >
              {description}
            </div>
          )}
      </div>
      <div className="matrix-table-container">
        {dataSource.length && columns.length && (
          <table className="matrix-table">
            <colgroup></colgroup>
            <thead className={isLongTable ? undefined : 'ant-table-thead'}>
              <tr>
                {columns.map((column, index) => (
                  <th
                    className={`matrix-th-${index}`}
                    key={`matrix-th-${column.title}-${index}`}
                  >
                    {column.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataSource.map((data, index) => (
                <tr
                  key={`matrix-row-${index}`}
                  className="ant-table-row"
                  data-testid={`question${data.question.id}`}
                >
                  {columns.map((column) => (
                    <td
                      key={column.key}
                      className={
                        isLongTable
                          ? lessPadding
                            ? 'less-padding'
                            : ' '
                          : 'ant-table-cell'
                      }
                    >
                      {column.render(data, data)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {showErrors === true && errors?.length ? (
        <div className={'error'}>* {t('errors.complete-question')}</div>
      ) : null}
    </div>
  );
};

export default QuestionsMatrixView;
