import React, { useContext, useEffect, useMemo, useState } from 'react';
import QuestionView from './QuestionView';
import QuestionsMatrixView from './QuestionsMatrixView';
import { useInstanceEvent, useStoreContext } from '../context/store';
import { QuestionsMatrix, Module as Question, getOneOf} from '@smile-labs/feedback-sdk';

import get from 'lodash/get';
import set from 'lodash/set';
import useAnswers from '../hooks/useAnswers';
import { LanguageContext } from '../context/LanguageContext';

const PollView = ({postFormData}) => {
  const { context, dispatch, instance } = useStoreContext();
  const { language } = useContext(LanguageContext);
  const answers = useInstanceEvent('nav', (instance) => instance.nav.answers);
  const { splitModuleComponents, autoStep } = instance.settings.nav;

  const {
    startIndex,
    nextStepIndex,
    atLastPage,
  } = context.ui;

  const pageStarts = useInstanceEvent('nav', instance => instance.nav.pageStarts);

  const [questionsPage, setQuestionPage] = useState([]);
  const { updateAnswer } = useAnswers();

  const { campaign} = context.data;

  const poll = useInstanceEvent('poll', instance => instance.poll);
  const progressSaving = get(campaign, 'metadata.settings.progress_saving', false);

  const delay = get(poll, 'settings.delay', 0.3);
  const enabledModules = useInstanceEvent('modules:change', instance => instance.modules)

  const currentQuestion = enabledModules.find((question, index) => index === startIndex);

  const setAtLastPage = (atLastPage) => {
    dispatch({
      type: 'ui.update',
      payload: {
        atLastPage,
      },
    });
  }

  useEffect(() => {
    const view = (() => {
      if (currentQuestion && currentQuestion.score_type == null && currentQuestion.type === 'question') {
        return 'motives';
      } else {
        return 'question';
      }
    })();
    dispatch({
      type: 'ui.update',
      payload: {
        currentView: view,
      },
    });
  },[])



  useEffect(() => {
    /*
      Con el startIndex actual, buscar dentro de breaks que  nextStepIndex y prevStepIndex le corresponden
    */
    const currentPageStartIndex = pageStarts.findIndex(index => index === startIndex);

    const nextStepIndex = pageStarts[currentPageStartIndex + 1];
    const prevStepIndex = pageStarts[currentPageStartIndex - 1];

    dispatch({
      type: 'ui.update',
      payload: {
        prevStepIndex,
        nextStepIndex,
      },
    });

    const newQuestionsPage = enabledModules.slice(startIndex, nextStepIndex);
    const atLast = newQuestionsPage.reduce((ret, question) => {
      const {isLast, thanks} = question.getNext(answers);

      if (thanks) {
        dispatch({
          type: 'data.update',
          payload: {
            thanks:{...thanks},
          },
        });
      } else {
        if (isLast) {
          const data = context.data;
          const thanks = getOneOf([data.campaign, data.device, data.encuesta], 'metadata.thanks');

          set(thanks, 'redes', getOneOf([data.campaign, data.device, data.encuesta], 'metadata.redes'));
          dispatch({
            type: 'data.update',
            payload: {
              thanks,
            },
          });
        }
      }

      return ret || isLast;
    }, false);
    setAtLastPage(atLast);

    setQuestionPage(newQuestionsPage);
  }, [instance, startIndex, pageStarts, enabledModules,  language]);

  useEffect(() => {
    dispatch({
      type: 'data.update',
      payload: {
        currentQuestion: currentQuestion,
      },
    });
    if(!splitModuleComponents) return;
    if(!autoStep) return;
    const moduleAnswer = instance.getModuleAnswerByIndex(get(currentQuestion,'index',0));
    const scoreErrors = currentQuestion.getScoreErrors({answer:moduleAnswer});
    if(currentQuestion.score_type !== null && scoreErrors.length === 0 && currentQuestion.hasMotives()){
      dispatch({
        type: 'ui.update',
        payload: {
          currentView: 'motives',
        },
      });
    }

  },[answers,currentQuestion, enabledModules]);

  useEffect(() => {
    const singleQuestions = questionsPage.reduce((reduced, question) => {
      return [...reduced, ...question.getSubModules()];
    }, []);

    singleQuestions.forEach((question) => {
      const moduleAnswer = instance.getModuleAnswerByIndex(question.index);
      if (moduleAnswer) moduleAnswer.visited = true;
    });
  }, [instance, questionsPage]);

  const errorsInPage = useMemo(() => questionsPage.reduce((reduced, item) => {
    const errors = item.getErrors({ answers });
    return [...reduced, ...errors];
  }, []), [answers, questionsPage]);

  const nextStepEnabled = errorsInPage.length === 0;


  useEffect(() => {
    dispatch({
      type: 'ui.update',
      payload: {
        nextStepEnabled,
      },
    });
  }, [dispatch, nextStepEnabled]);

  useEffect(() => {
    if(!questionsPage || questionsPage.length ===0) return;
    // for autostep
    const pageHaveNoRequiredQuestion =
      questionsPage.length > 0
        ? questionsPage.reduce((reduce, question) => {
          const answer = answers.find(
            (answer) => get(answer, 'module.id') === get(question, 'id')
          );
          return (
            reduce +
            (get(question, 'metadata.settings.required', true) ||
              (!get(question, 'metadata.settings.required', true) &&
                get(answer, 'dirty')))
          );
        }, 0)
        : null;

    let pageStepable = 0;
    questionsPage.forEach(question=> {
      if(question.isStepable(answers)===1) pageStepable++;
    });

    if(!currentQuestion) return;
    
    const currentPageStartIndex = pageStarts.findIndex(index => index === startIndex);
    const nextStepIndex = pageStarts[currentPageStartIndex + 1];
    const firstQuestionNextPage = enabledModules.find((question, index) => index === nextStepIndex);

    const nextPageAnswer = answers.find(
      (answer) => answer.module.id === get(firstQuestionNextPage, 'id')
    );
    const isNextQuestionWasVisited = nextPageAnswer?.visited && nextPageAnswer?.dirty;

    const isMatrix = () => {
      let isMatrix = undefined;
      questionsPage.forEach(question => {
        if(question instanceof QuestionsMatrix) {
          isMatrix = true;
        }
      })
      return isMatrix;
    }

    const matrixAnswered = () => {
      let answered = []
      questionsPage.forEach((question, index) => {
        if(question instanceof QuestionsMatrix) {
          question.modules.forEach((matrixQuestion, i) => {
            const answer = answers.find(
              (answer) => get(answer, 'module.id') === get(question, `modules.${i}.id`)
            );
            answered = [...answered, get(answer, 'score')];
          })
        }
      })
      return answered
    }

    if (
      !isNextQuestionWasVisited &&
      pageHaveNoRequiredQuestion !== 0 &&
      (pageStepable === 0 || isMatrix()) &&
      autoStep &&
      currentQuestion.type !== 'information' &&
      currentQuestion.type !== 'attachment' &&
      currentQuestion.type !== 'form' &&
      nextStepEnabled &&
      nextStepIndex !== undefined &&
      !atLastPage
    ) {
      if(isMatrix() && matrixAnswered().includes(undefined)) return;
      window.scrollTo(0, 0);

      const currentView = !get(firstQuestionNextPage, 'score_type') ? 'motives' : 'question';
      dispatch({
        type: 'data.update',
        payload: {
          showErrors: false,
        },
      });
      setTimeout(function () {
        if (progressSaving) postFormData(false);
        return dispatch({
          type: 'ui.update',
          payload: {
            startIndex: nextStepIndex,
            currentView,
          },
        });
      }, delay);
    }
  }, [
    nextStepEnabled,
    nextStepIndex,
    enabledModules,
  ]);

  return (
    <div data-testid="questions" className={'principal'}>
      {questionsPage.map((question, index) => {
        if (question instanceof Question)
          return (
            <QuestionView
              key={question.id}
              {...{
                module: question,
                answer: answers[question.index],
                onChange: (payload) => {
                  dispatch({
                    type: 'data.update',
                    payload: {
                      showErrors: false,
                    },
                  });
                  updateAnswer(question.index, payload);
                },
              }}
            />
          );
        if (question instanceof QuestionsMatrix)
          return (
            <QuestionsMatrixView
              key={index}
              {...{
                questions: question.questions,
                questionsMatrix: question,
                values: answers,
              }}
            />
          );
        return null;
      })}
    </div>
  );
};

export default PollView;
