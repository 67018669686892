/* eslint-disable no-loop-func */
import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import Button from '../../components/ui/Button';
import PollView from '../../components/PollView';
import Presentation from '../../components/FormParts/Presentation';
import Links from '../../components/FormParts/Links';
import UserDataFields from '../../components/UserDataFields';
import CommentFields from '../../components/CommentFields';
import UserDataForm from '../../components/UserDataForm';
import Arrows from '../../components/FormParts/Arrows';
import Smilehelper from '../../utils/Smilehelper';
import get from 'lodash/get';
import { useInstanceEvent, useStoreContext } from '../../context/store';
import { useHistory, useLocation } from 'react-router-dom';
import { enabled, log } from '../../utils/debug';
import { useI18n } from '../../utils/i18n';
import { useFormData } from '../../hooks/form';
import { parse } from 'query-string';
import merge from 'lodash/merge';
import { validateRequiredFields } from '../../utils/validateRequiredFields';
import { useTheme } from '../../hooks/sdk';
import { getStorageItem } from '../../utils/browser';
import ProgressBar from '../../components/FormParts/ProgressBar';
import PoweredBySmile from '../../components/FormParts/PoweredBySmile';
import Header from '../../components/FormParts/Header';
import { useChangeLanguage } from '../../utils/hooks';
import useBuildSteps from '../../hooks/useBuildSteps';
import { buildFormDataPayload } from '../../utils/core';
import useBodyStyle from '../../hooks/useBodyStyle';
import SmileSpinner from '../../utils/SmileSpinner';
import WindowTitle from 'window-title';
import { BOLD_REGEX, ITALIC_REGEX, UNDERLINE_REGEX, replaceBold, replaceItalic, replaceUnderline } from '../../utils/replaceVariables';
import { isTimestampInThePast } from '../../utils/date';
import LanguageSelector from '../../components/LanguageSelector';
import { LanguageContext } from '../../context/LanguageContext';

function replaceFormat() {
  const elements = document.querySelectorAll('*:not(script):not(style)');
  if(!elements.length) return;
  elements.forEach((element)=>{
    // Aplicar el reemplazo
    if(!['pregunta','question-description','presentation-text'].includes(element.className)) return;

    if(BOLD_REGEX.test(element.textContent)){
      element.innerHTML = element.innerHTML.replace(BOLD_REGEX, replaceBold);
    } 
    if(ITALIC_REGEX.test(element.textContent)){
      element.innerHTML = element.innerHTML.replace(ITALIC_REGEX, replaceItalic);
    } 
    if(UNDERLINE_REGEX.test(element.textContent)){
      element.innerHTML = element.innerHTML.replace(UNDERLINE_REGEX, replaceUnderline);
    } 
  })

}


const QuestionsStep = ({ postFormData}) => {
  return (
    <Fragment>
      <PollView
        postFormData={postFormData}
      />
    </Fragment>
  );
};

const Form = ({ data }) => {
  const formData = useFormData(data);
  const { context, dispatch, instance } = useStoreContext();
  const location = useLocation();
  const query = useMemo(() => parse(location.search), [location.search]);
  
  useBodyStyle(); // Set body styles from theme

  const history = useHistory();
  const settings = useInstanceEvent(
    'settings',
    (instance) => instance.settings
  );

  const t = useI18n();
  const { userData } = context;
  const answers = useInstanceEvent('nav', (instance) => instance.nav.answers);

  const enabledModules = useInstanceEvent(
    'modules:change',
    (instance) => instance.modules
  );
  const poll = useInstanceEvent('poll', (instance) => instance.poll);
  const { progress } = useInstanceEvent('nav', (instance) => instance.nav);

  const [busy, setBusy] = useState(false);

  const { device, campaign} = context.data;

  const theme = useTheme();

  useChangeLanguage({ language: poll?.language ?? 'es' });

  const { hash } = context.state;

  const { atLastPage, working, prevStepIndex } = context.ui;

  const hasQuestionForm = useMemo(() => {
    return get(poll, 'modules', []).some((question) => {
      return get(question, 'type', '') === 'form';
    });
  }, [poll]);

  const setUserData = (payload) => {
    dispatch({
      type: 'userData.update',
      payload,
    });
  };

  const autoSave =
    get(query, 'v') &&
    get(campaign, 'metadata.settings.auto-save-first-question');
  const progressSaving = get(campaign, 'metadata.settings.progress_saving', false);
  const i18nextLng = getStorageItem('i18nextLng');

  useEffect(() => {
    const openCampaignExpiration = get(campaign, 'metadata.settings.open_campaign_expiration.enabled', false);
    const openCampaignExpirationKey = get(campaign, 'metadata.settings.open_campaign_expiration.key', null);
    if(openCampaignExpiration && openCampaignExpirationKey) {
      const timestamp = get(query, openCampaignExpirationKey, null);
      if(timestamp) {
        try {
          if(isTimestampInThePast(timestamp)) {
            dispatch({
              type: 'data.update',
              payload: {
                expired: true,
              },
            });
            history.push(`/${hash}/thanks`);
          }  
        } catch(error) {
          console.error(error);
        }  
      }
    }
  }, [get(campaign, 'metadata.settings.open_campaign_expiration.enabled'), get(campaign, 'metadata.settings.open_campaign_expiration.key')]);

  useEffect(() => {
    const element = document.getElementsByTagName('html');
    element[0].setAttribute('lang', i18nextLng);
  }, [i18nextLng]);

  const isIframe = hash ? hash.includes('iframe') : null;

  const [currentStep, setCurrentStep] = useState(undefined);

  useEffect(() => {
    const value = get(query, 'v');
    if (value !== undefined && answers[0]) {
      answers[0].setScoreByValue(parseInt(value));
    }
  }, [query, answers]);

  const steps = useBuildSteps({
    campaign,
    hasQuestionForm,
  });

  const requiredFieldsAreValid = useMemo(
    () =>
      validateRequiredFields(
        {
          ...context,
          answers,
        },
        enabledModules
      ),
    [answers, context, enabledModules]
  );

  const buildDataToSend = useCallback(
    async (firstVote) => {
      if (!firstVote && !progressSaving) {
        dispatch({
          type: 'data.update',
          payload: {
            showErrors: true,
          },
        });
        if (!requiredFieldsAreValid) return;
      }

      const form = {};
      if (instance.sdk.externalId !== undefined)
        merge(form, {
          contact: {
            external_id: instance.sdk.externalId,
          },
        });

      merge(form, {
        ...context.userContactData.reduce((reduced, field) => {
          const target = field.target || 'campaign';
          reduced[target] = reduced[target] || {};
          if (field.custom || field.target === 'campaign')
            reduced[target].custom_data = reduced[target].custom_data || {};

          Object.assign(
            field.custom ? reduced[target].custom_data : reduced[target],
            {
              [field.name]: (() => {
                if (field.response && field.response.toDate) {
                  return field.response.format();
                }
                return field.response;
              })(),
            }
          );

          return reduced;
        }, {}),
      });

      const data = {
        store_key: get(context, 'data.store.key'),
        hash,
        deviceType: device.deviceType,
        url_from: get(context, 'data.url_from.url_from'),
        userContext: instance.sdk.userContext,
        userData,
        form,
      };

      // VALIDO LAS RESPUESTAS QUE ESTAN EN EL CAMINO

      // Validate Poll Questions

      if (get(poll, 'modules', []).length) {
        const dataAnswers = [];

        const common = {
          ...userData,
          poll_id: poll.id,
        };

        if (device)
          Object.assign(common, {
            device_id: device.id,
          });

        const answerInQuestions = (answer) => {
          return enabledModules.find((question) => {
            return question.matchId(answer.module.id);
          });
        };

        const answersValid = answers.filter((answer) =>
          answerInQuestions(answer)
        );
        if (firstVote && answersValid.length > 0) {
          const dato = answersValid[0];
          data.answers = [
            {
              ...common,
              id: dato.module.id,
              score: dato.score,
              comment: dato.comment,
            },
          ];
          return data;
        }
        answersValid.forEach((answer, index) => {
          let question = enabledModules.find((question) => {
            return question.matchId(answer.module.id);
          });
          if (get(question, 'modules')) {
            question = get(question, 'modules', []).find(
              (questionMatrix) => questionMatrix.id === answer.module.id
            );
          }
          if(question.type === 'form') return;
          if(question.type === 'question' && !answer.score && question.score_type !== null) return;
          if(question.type === 'question' && !answer.score && !answer.comment && get(answer, 'motives', []).length === 0) return;

          const motiveQuestion = question.type === 'question' && question.score_type === null && question.motives.length > 1;
          if (motiveQuestion && get(answer, 'motives', []).length === 0) return;
          if (answer.motives.length === 0) {
            const payload = {
              ...common,
              id: answer.module.id,
            };
            if (question.type === 'question') {
              Object.assign(payload, {
                score: answer.score,
                comment: answer.comment,
              });
              dataAnswers.push(payload);
              return;
            }
            if (question.type === 'attachment') {
              Object.assign(payload, {
                src: get(answer, 'src.src'),
              });
              dataAnswers.push(payload);
              return;
            }
          }
          answer.motives.forEach((motivo) => {
            const entry = {
              ...common,
              motivo,
              id: answer.module.id,
              score: answer.score,
              comment: answer.comment,
            };

            dataAnswers.push(entry);
          });
        });

        data.answers = dataAnswers;
      }
      data.progress_percentage = get(progress, 'percentage', 0);
      return data;
    },
    [
      answers,
      context,
      device,
      dispatch,
      enabledModules,
      hash,
      instance.sdk.externalId,
      instance.sdk.userContext,
      poll,
      requiredFieldsAreValid,
      userData,
    ]
  );

  const postFormData = useCallback(
    async (isFinishing = true) => {
      if (busy) return;
      setBusy(true);
      const buildOnlyFirstVote = (isFinishing || progressSaving) ? false : true;
      const dataToSend = await buildDataToSend(buildOnlyFirstVote);

      if (!dataToSend) {
        // data is invalid
        setBusy(false);
        return;
      }

      if (!isFinishing && get(dataToSend, 'answers', []).length === 0) {
        setBusy(false);
        // answers is invalid for isFinishing = false
        return;
      }

      if (enabled()) {
        setBusy(false);
        log('dataToSend', dataToSend);
        return;
      }

      try {
        const { hash } = dataToSend;
        const payload = buildFormDataPayload(dataToSend);
        if (
          get(context, 'session.requestDone') &&
          !['playground'].includes(hash)
        ) {
          const firstQuestionIsAnswered = (get(payload, 'answers', []).length > 0 && get(payload, 'answers.0.score', null)) ? true : false; // agregar condiciones
          if ((autoSave && isFinishing) || (progressSaving && get(context, 'data.feedbackId', null) && firstQuestionIsAnswered)) {
            await Smilehelper.updateFeedback(get(context, 'data.feedbackId', null), hash, payload);
            setBusy(false);
          } else if ((!autoSave && isFinishing) || (autoSave && !isFinishing) || (progressSaving && !get(context, 'data.feedbackId', null) && firstQuestionIsAnswered)) {
            try{
              const response = await Smilehelper.postDatos(hash, payload);
              const commentResponse = get(
                response,
                'responses.0.commentResponse'
              );
              await dispatch({
                type: 'data.update',
                payload: {
                  dataToSend: payload,
                },
              });
              if (commentResponse) {
                await dispatch({
                  type: 'data.update',
                  payload: {
                    ai: {
                      aiResponse: commentResponse.trim(),
                    },
                  },
                });
              }
              if ((autoSave && !isFinishing) || (progressSaving && !get(context, 'data.feedbackId', null))) {
                dispatch({
                  type: 'data.update',
                  payload: {
                    feedbackId: response.id,
                  },
                });
              }
            } catch(error){
              dispatch({
                type: 'data.update',
                payload: {
                  expired: true,
                },
              });
              history.push(`/${hash}/thanks`);
              return;
            };
          }
        }
        if (!isFinishing){
          setBusy(false);
          return;
        } 
        history.push({
          ...location,
          pathname: hash ? `/${hash}/thanks` : '/thanks',
        });

        // Clear things
        await dispatch({
          type: 'ui.clear',
        });
        await dispatch({
          type: 'userContactData.clear',
        });
        await dispatch({
          type: 'data.update',
          payload: {
            showErrors: false,
          },
        });
      } catch (err) {
        console.error(err);
      } finally {
        setBusy(false);
      }
    },
    [
      busy,
      autoSave,
      progressSaving,
      buildDataToSend,
      context,
      dispatch,
      get(context, 'data.feedbackId'),
      history,
      location,
    ]
  );

  const { setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    const query = parse(location.search);
    const lang = get(query,'lang',get(poll, 'language', 'es'));
    if(lang) {
      setLanguage(lang.toLowerCase());
      delete query.lang;
    }
  }, [get(poll, 'language'), location.search]);

  useEffect(() => {
    if (get(context, 'data.feedbackId', null)) return;
    if (answers.length === 0) return;
    if (!autoSave) return;
    if (progressSaving) return;
    postFormData(false);
  }, [get(context, 'data.feedbackId'), answers, autoSave, postFormData]);

  // useEffect(() => {
  //   if (!progressSaving) return;
  //   if (answers.length === 0) return;
  //   postFormData(false);
  // }, [get(context, 'data.currentQuestion.id')]);

  const setFavicon = (url) => {
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = url;
    document.getElementsByTagName('head')[0].appendChild(link);
  };
  
  useEffect(() => {
    if(campaign?.tabSrc) setFavicon(campaign.tabSrc);
  }, [campaign]);


  const sendDisabled = useMemo(() => {
    return busy || working || !requiredFieldsAreValid;
  }, [busy, working, requiredFieldsAreValid]);

  async function prepareDataToSend() {
    if (sendDisabled) {
      dispatch({
        type: 'data.update',
        payload: {
          showErrors: true,
        },
      });
      return;
    }
    postFormData(true);
  }

  useEffect(()=>{
    const element = document.getElementsByTagName('body')[0];
    const hasBoldText = /\*\*(.*?)\*\*/g.test(element.textContent);
    const hasItalicText = /\*(.*?)\*/g.test(element.textContent);
    const hasUnderlineText = /#(.*?)#/g.test(element.textContent);
    if(!hasBoldText && !hasItalicText && !hasUnderlineText)return;
    replaceFormat();
  });
  

  if (!formData) {
    return (
      <div
        style={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SmileSpinner data-testid="spinner" />
      </div>
    );
  }

  const { footer } = settings.ui ?? {};

  const showPoweredBySmile = get(poll, 'settings.showPoweredBySmile', true);
  const showProgressBar =
    get(poll, 'settings.showProgressBar', false) &&
    currentStep?.type === 'questions';
  const showHeader =
    !currentStep || (currentStep && currentStep.type !== 'thanks' && !isIframe);
  const showLinks = campaign?.type === 'link';

  return (
    <div className="container">
      {(get(poll,'metadata.translations.enabled', false) && prevStepIndex === undefined) && <LanguageSelector />}
      {campaign?.tab && <WindowTitle title={campaign.tab} />}
      {showHeader && <Header />}
      {showLinks && <Links />}
      {currentStep && (
        <div
          className="inside"
          // style={{
          //   flexGrow: 1,
          // }}
        >
          {currentStep.type === 'presentation' && <Presentation />}
          {currentStep.type === 'form' && !hasQuestionForm && (
            <UserDataForm campaign={campaign} />
          )}
          {currentStep.type === 'questions' && (
            <div>
              <div>
                <QuestionsStep postFormData={postFormData}/>
              </div>
            </div>
          )}
          {atLastPage && currentStep.type === 'questions' && (
            <div>
              {' '}
              {get(poll, 'settings.commentsFields') && (
                <CommentFields encuesta={poll} setUserData={setUserData} />
              )}
              {get(poll, 'settings.userDataFields') && (
                <UserDataFields
                  encuesta={poll}
                  setUserData={setUserData}
                  userData={userData}
                />
              )}
            </div>
          )}
        </div>
      )}
      <div className="footer">
        <Arrows
          postFormData={postFormData}
          steps={steps}
          onStepChange={setCurrentStep}
          renderSendButton={() =>
            theme.buttonType === 'default' ? (
              <Button
                testid="send-button"
                style={
                  sendDisabled
                    ? {
                        backgroundColor: '#8a8a8a',
                        color: 'white',
                      }
                    : null
                }
                className={'boton'}
                onClick={() => prepareDataToSend()}
              >
                {t('send')}
              </Button>
            ) : (
              <Button
                pill
                theme="secondary"
                testid="send-button"
                className="shadowed"
                size="sm"
                style={
                  sendDisabled
                    ? {
                        backgroundColor: '#8a8a8a',
                        color: 'white',
                      }
                    : null
                }
                // className={'boton'}
                onClick={() => prepareDataToSend()}
              >
                {t('send')}
                <img
                  style={{ width: '16px' }}
                  src={
                    'https://www.flaticon.com/svg/static/icons/svg/736/736212.svg'
                  }
                />
              </Button>
            )
          }
        />
        {showProgressBar && <ProgressBar />}
        {footer && (
          <div style={{ width: '100%' }}>
            <img style={{ width: '100%' }} src={footer} />
          </div>
        )}
        {showPoweredBySmile && <PoweredBySmile />}
      </div>
    </div>
  );
};

export default Form;
